import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import TimeEntryForm from './components/TimeEntryForm';
import TimeEntryList from './components/TimeEntryList';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Register from './components/Register';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await axios.get('https://backend-wuerbel-hjbeaaa4fbfdb7gn.westeurope-01.azurewebsites.net/api/current-user/');
          setCurrentUser(response.data);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error fetching current user:', error);
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
        }
      }
      setIsLoading(false);
    };

    checkAuthStatus();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <header className="bg-blue-600 text-white p-4">
          <div className="container mx-auto flex justify-between items-center">
            <h1 className="text-2xl font-bold">Wuerbel Zeiterfassung</h1>
            <nav>
              <ul className="flex space-x-4">
                {isAuthenticated ? (
                  <>
                    <li><Link to="/" className="hover:underline">Time Entries</Link></li>
                    <li><Link to="/add" className="hover:underline">Add Entry</Link></li>
                    <li><Link to="/dashboard" className="hover:underline">Dashboard</Link></li>
                    <li><button onClick={handleLogout} className="hover:underline">Logout</button></li>
                  </>
                ) : (
                  <>
                    <li><Link to="/login" className="hover:underline">Login</Link></li>
                    <li><Link to="/register" className="hover:underline">Register</Link></li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </header>
        {isAuthenticated && currentUser && (
          <div className="bg-gray-100 p-2 text-right">
            <div className="container mx-auto">
              Welcome, {currentUser.username}!
            </div>
          </div>
        )}
        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/login" element={!isAuthenticated ? <Login setIsAuthenticated={setIsAuthenticated} setCurrentUser={setCurrentUser} /> : <Navigate to="/" />} />
            <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate to="/" />} />
            <Route path="/" element={isAuthenticated ? <TimeEntryList /> : <Navigate to="/login" />} />
            <Route path="/add" element={isAuthenticated ? <TimeEntryForm /> : <Navigate to="/login" />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;