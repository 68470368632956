import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Dashboard() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [filter, setFilter] = useState({ userId: '', project: '', startDate: '', endDate: '' });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [entriesResponse, currentUserResponse] = await Promise.all([
          axios.get('https://backend-wuerbel-hjbeaaa4fbfdb7gn.westeurope-01.azurewebsites.net/api/dashboard-data/'),
          axios.get('https://backend-wuerbel-hjbeaaa4fbfdb7gn.westeurope-01.azurewebsites.net/api/current-user/')
        ]);
        setTimeEntries(entriesResponse.data);
        setFilteredEntries(entriesResponse.data);
        setCurrentUser(currentUserResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = timeEntries.filter(entry => 
      (filter.userId === '' || entry.user_id.toString() === filter.userId) &&
      (filter.project === '' || entry.project.toLowerCase().includes(filter.project.toLowerCase())) &&
      (filter.startDate === '' || entry.date >= filter.startDate) &&
      (filter.endDate === '' || entry.date <= filter.endDate)
    );
    setFilteredEntries(filtered);
  }, [filter, timeEntries]);

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const uniqueUsers = Array.from(new Set(timeEntries.map(entry => entry.user_id)));
  const uniqueProjects = Array.from(new Set(timeEntries.map(entry => entry.project)));

  const calculateHours = (start, end) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    return (endHours - startHours) + (endMinutes - startMinutes) / 60;
  };

  const chartData = {
    labels: uniqueProjects,
    datasets: [
      {
        label: 'Total Hours',
        data: uniqueProjects.map(project => 
          filteredEntries
            .filter(entry => entry.project === project)
            .reduce((total, entry) => total + calculateHours(entry.start_time, entry.end_time), 0)
        ),
        backgroundColor: 'rgba(59, 130, 246, 0.6)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Hours by Project',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Hours',
        },
      },
    },
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold mb-4">Time Entries Dashboard</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {currentUser && currentUser.is_staff && (
            <div>
              <label htmlFor="userId" className="block text-sm font-medium text-gray-700 mb-1">Filter by User</label>
              <select 
                name="userId" 
                onChange={handleFilterChange} 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="">All Users</option>
                {uniqueUsers.map(userId => (
                  <option key={userId} value={userId.toString()}>
                    {timeEntries.find(entry => entry.user_id === userId)?.username}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label htmlFor="project" className="block text-sm font-medium text-gray-700 mb-1">Filter by Project</label>
            <select 
              name="project" 
              onChange={handleFilterChange} 
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">All Projects</option>
              {uniqueProjects.map(project => (
                <option key={project} value={project}>{project}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
            <input 
              type="date" 
              id="startDate" 
              name="startDate" 
              value={filter.startDate} 
              onChange={handleFilterChange} 
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
            <input 
              type="date" 
              id="endDate" 
              name="endDate" 
              value={filter.endDate} 
              onChange={handleFilterChange} 
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">Hours per Project</h3>
          <Bar data={chartData} options={chartOptions} />
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">Summary Statistics</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
              </svg>
              <span>Total Users: {uniqueUsers.length}</span>
            </div>
            <div className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
                <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
              </svg>
              <span>Total Projects: {uniqueProjects.length}</span>
            </div>
            <div className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
              </svg>
              <span>Date Range: {filteredEntries.length > 0 ? `${filteredEntries[0].date} to ${filteredEntries[filteredEntries.length - 1].date}` : 'N/A'}</span>
            </div>
            <div className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
              </svg>
              <span>Total Hours: {filteredEntries.reduce((total, entry) => total + calculateHours(entry.start_time, entry.end_time), 0).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              {currentUser && currentUser.is_staff && (
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
              )}
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Project</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Start Time</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">End Time</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Description</th>
            </tr>
          </thead>
          <tbody>
            {filteredEntries.map((entry) => (
              <tr key={entry.id}>
                {currentUser && currentUser.is_staff && (
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.username}</td>
                )}
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.date}</td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.project}</td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.start_time}</td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.end_time}</td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{entry.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;